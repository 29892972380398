/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
    font-family: 'Arial', sans-serif;
    font-weight: 100;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
p{
    line-height: 1.8rem;
    margin-bottom: 1.2rem;
}
h2,h4,p{
    font-family: EB Garamond, sans-serif;
}
a:hover{
    text-decoration: none;
}
hr{
    display: block;
    width: 40%;
    margin: 2rem auto;
}
@font-face {
    font-family: eyesome;
    src: url(./assets/font/eyesome.otf);
}
.cursive{
    font-family: eyesome;
}
.text-xsmall{
    font-size: .8rem !important;
    line-height: 1.4rem !important;
}
.text-small{
    font-size: 1.2rem !important;
    line-height: 1.8rem !important;
}
.text-medium{
    font-size: 2.4rem !important;
    line-height: 3rem !important;
}
.text-large{
    font-size: 4.8rem !important;
    line-height: 5.4rem !important;
}
.text-xlarge{
    font-size: 6rem !important;
    line-height: 6.6rem !important;
}
.text-white{
    color: #ffffff !important;
}
.text-grey{
    color: #8b8b8b !important;
}
.text-pink{
    color: #f08e80 !important;
}
.text-garamond{
    font-family: "EB Garamond", sans-serif !important;
}
.text-bold{
    font-weight: 700 !important;
}
.text-underline{
    text-decoration: underline !important;
}
.bg-blue{
    background-color: #c8dcfa4d !important;
}
.bg-pink{
    background-color: #fdf0e6 !important;
}
.navigation{
    position: fixed;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100px;
    z-index: 1;
    justify-content: space-between;
    padding: 0 0.5rem;
}
.navigation a{
    display: block;
    width: 200px;
    margin: 0;
    box-sizing: border-box;
    padding: 2rem;
    text-align: center;
    color: #fff;
}
.header,
.invitation{
    display: block;
    min-height: 100vh;
    background-color: #fff;
    position: relative;
    background-image: url('./assets/img/flowers-white.png');
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
}
.header .main-info,
.invitation .main-info{
    display: block;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
}
.header h1,
.invitation h1{
    font-size: 12rem;
    margin-bottom: 4rem;
}
.header img,
.invitation img{
    display: block;
    width: 100%;
    position: absolute;
    bottom: 0;
    margin: 0 auto;
}
.section {
    box-sizing: border-box;
    padding: 10rem 0;
    text-align: center;
}
.message p{
    display: block;
    margin: 0 auto;
    text-align: center;
    line-height: 2rem;
}
.couple img{
    width: 100%;
}
.groom{
    text-align: left;
}
.bride{
    text-align: right;
}
.couple .groom img{
    float: right;
}
.couple .bride img{
    float: left;
}
.message,
.couple,
.rsvp{
    background-color: #fdf0e6;
}
#rsvp,
#needRide{
    width: 100%;
}
.rsvp-message{
    font-size: 3.8rem;
}
.rsvp-button{
    display: block;
    width: 200px;
    margin: 0 auto;
    background-color: #f08e80;
    padding: 2rem;
    color: #fff;
    border: 0;
}
.rsvp-button:hover{
    background-color: #f36b57;
    color: #fff;
}
.rsvp-button:disabled{
    display: block;
    width: 200px;
    margin: 0 auto;
    background-color: #eee;
    padding: 2rem;
    color: #fff;
    border: 0;
}
.rsvp-button:disabled:hover{
    background-color: #eee;
    color: #fff;
}
.scrolled{
    background-color: #fff;
}
.footer-navigation{
    padding: 2rem 2rem 0;
    background-color: #f08e80;
}
.footer-navigation .navigation-items{
    display: flex;
    justify-content: space-between;
    max-width: 600px;
    margin: 0 auto;
}
.footer-navigation .navigation-items a{
    color: #fff;
}
.footer-navigation .navigation-items a:hover{
    color: #ffd8a6;
}
.footer{
    padding: 2rem;
    color: #fff;
    background-color: #f08e80;
}
.rsvp-form,
.details,
.faq,
.motif{
    min-height: 100vh;
    padding: 10rem 0 30rem;
    background-image: url('./assets/img/flowers-white.png');
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
}
.rsvp-form label{
    padding-bottom: 1rem;
}
.rsvp-form input{
    font-size: 1.5rem;
    padding: 1rem 1.5rem;
}
.rsvp-form select{
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
}
.motif .color1{
    display: inline-block;
    margin: 0 1rem 2rem;
    height: 200px;
    width: 200px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background-color: #fa92b1;
}
.motif .color2{
    display: inline-block;
    margin: 0 1rem 2rem;
    height: 200px;
    width: 200px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background-color: #ffc2c3;
}
.motif .color3{
    display: inline-block;
    margin: 0 1rem 2rem;
    height: 200px;
    width: 200px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background-color: #ffd8a6;
}
.motif .color4{
    display: inline-block;
    margin: 0 1rem 2rem;
    height: 200px;
    width: 200px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background-color: #ffe497;
}
.motif img{
    display: block;
    width: 100%;
}
.spinner {
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    margin: 0 auto;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
/* mobile */
@media screen and (max-width: 1180px){
    .groom p,
    .bride p{
        line-height: 1.5rem !important;
    }
    .extra-message{
        line-height: 7rem !important;
    }
}
@media screen and (max-width: 767px){
    .desktop {
        display: none;
    }
    .mobile {
        display: block;
    }
    .navigation{
        background-color: #fff;
    }
    .navigation a{
        font-size: 1.2rem !important;
        padding: 2rem 0;
        width: 150px !important;
    }
    .navigation a{
        width: 110px;
        padding: 1rem;
    }
    .header{
        background-size: cover;
    }
    .header h1{
        font-size: 6rem;
    }
    .invitation{
        min-height: 160vh;
    }
    .rsvp-form,
    .details,
    .faq,
    .motif{
        min-height: 100vh;
        padding: 10rem 0 10rem;
    }
    .extra-message{
        font-size: 3rem !important;
        line-height: 4.5rem !important;
    }
    .message p{
        line-height: 2rem;
    }
}
@media screen and (max-width: 600px){
    .section{
        padding: 5rem 0;
    }
    .details, .faq, .motif, .rsvp-form{
        background-size: 320%;
    }
    .couple{
        background-color: #fff;
    }
    .rsvp-message{
        font-size: 2rem;
    }
}
/* desktop */
@media screen and (min-width: 768px){
    .desktop {
        display: block;
    }
    .mobile {
        display: none;
    }
}
/* print */
@media print {
    .no-print{
        display: none !important;
    }
}